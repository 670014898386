import * as React from 'react'

import Footer from './footer'
import WhatsApp from './whatsapp'

export default function Layout ({ children }) {
  return (
    <>
        <main>
          {children}
        </main>
      <Footer/>
      <WhatsApp />
    </>
  )
}

import * as React from 'react'

import { graphql, useStaticQuery } from 'gatsby'

import WhatsAppVector from '../vectors/whatsapp'

export default function WhatsApp() {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    whatsapp
                }
            }
        }
    `)

    const href = `${site.siteMetadata.whatsapp}`

    return (
        <div className="fixed fill-white shadow-lg flex place-items-center justify-center bottom-6 p-3 text-white right-6 rounded-full bg-[#25D366]">
            <a href={href}>
                <WhatsAppVector />
            </a>
        </div>
    )
}
